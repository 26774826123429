<template>
  <div>
    <div class="relative mt-2 flex items-center">
      <input
          type="text"
          v-model="searchTerm"
          @input="onInput()"
          @blur="validateFields('State', searchTerm)"
          placeholder="States..."
          class="flex-1 border-0 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 m-0.5 p-1"
      />

      <input type="hidden" :value="selectedStateCode" name="state_code" />

      <input
          type="text"
          v-model="plateNum"
          @input="onInput()"
          @blur="validateFields('Plate number', plateNum)"
          placeholder="Plate number..."
          class="flex-1 border-0 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 m-0.5 p-1"
      />

    </div>
    <div class="relative mt-2 flex items-center bg-white-900">
      <ul v-if="showList" class="divide-y divide-gray-100" role="list">
        <li v-for="state in filteredStates"
            :key="state.code"
            @click="selectState(state)"
            class="py-4" >
          <div class="flex items-center gap-x-3">
            {{ state.name }}
          </div>
        </li>
      </ul>
    </div>

    <div class="relative mt-2 flex items-center columns-6">
      <input
          type="text"
          v-model="unitNum"
          @input="onInput()"
          @blur="validateFields('Unit number', unitNum)"
          placeholder="Unit number..."
          class="flex-1 border-0 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 m-0.5 p-1"
      />
    </div>

    <div class="relative mt-2 flex items-center columns-6">
      <input
          type="text"
          v-model="vinNum"
          @input="onInput()"
          @blur="validateFields('Vin number', vinNum)"
          placeholder="Vin number..."
          class="flex-1 border-0 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 m-0.5 p-1"
      />
    </div>

<!--    <div class="relative mt-2 flex items-center columns-6">
      <input
          type="text"
          v-model="leasingCompany"
          @input="onInput()"
          @blur="validateFields('Leasing company', leasingCompany)"
          placeholder="Leasing company..."
          class="flex-1 border-0 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 m-0.5 p-1"
      />
    </div>-->

    <div class="mt-2 w-full max-w-full">
      <select  v-model="leasingCompany"
               name="leasingCompany"
               @change="onInput()"
               autocomplete="leasingCompany"
               class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6"
      >
        <option value="" selected="selected">Choose leasing company...</option>
        <option v-for="leasingCompany in leasingCompanies" :key="leasingCompany.id">{{ leasingCompany.name}}</option>
      </select>
    </div>

  </div>

</template>

<script>
export default {
  data() {
    return {
      unitNum: '',
      vinNum:'',
      plateNum: '',
      searchTerm: '',
      showList: false,
      selectedStateCode: '',
      leasingCompany: '',
      keyword: '',
      states: [
        { code: "AL", name: "Alabama" },
        { code: "AK", name: "Alaska" },
        {code: "AZ", name: "Arizona"},
        {code: "AR", name: "Arkansas"},
        {code: "CA", name: "California"},
        {code: "CO", name: "Colorado"},
        {code: "CT", name: "Connecticut"},
        {code: "DE", name: "Delaware"},
        {code: "FL", name: "Florida"},
        {code: "GA", name: "Georgia"},
        {code: "HI", name: "Hawaii"},
        {code: "ID", name: "Idaho"},
        {code: "IL", name: "Illinois"},
        {code: "IN", name: "Indiana"},
        {code: "IA", name: "Iowa"},
        {code: "KS", name: "Kansas"},
        {code: "KY", name: "Kentucky"},
        {code: "LA", name: "Louisiana"},
        {code: "ME", name: "Maine"},
        {code: "MD", name: "Maryland"},
        {code: "MA", name: "Massachusetts"},
        {code: "MI", name: "Michigan"},
        {code: "MN", name: "Minnesota"},
        {code: "MS", name: "Mississippi"},
        {code: "MO", name: "Missouri"},
        {code: "MT", name: "Montana"},
        {code: "NE", name: "Nebraska"},
        {code: "NV", name: "Nevada"},
        {code: "NH", name: "New Hampshire"},
        {code: "NJ", name: "New Jersey"},
        {code: "NM", name: "New Mexico"},
        {code: "NY", name: "New York"},
        {code: "NC", name: "North Carolina"},
        {code: "ND", name: "North Dakota"},
        {code: "OH", name: "Ohio"},
        {code: "OK", name: "Oklahoma"},
        {code: "OR", name: "Oregon"},
        {code: "PA", name: "Pennsylvania"},
        {code: "RI", name: "Rhode Island"},
        {code: "SC", name: "South Carolina"},
        {code: "SD", name: "South Dakota"},
        {code: "TN", name: "Tennessee"},
        {code: "TX", name: "Texas"},
        {code: "UT", name: "Utah"},
        {code: "VT", name: "Vermont"},
        {code: "VA", name: "Virginia"},
        {code: "WA", name: "Washington"},
        {code: "WV", name: "West Virginia"},
        {code: "WI", name: "Wisconsin"},
        {code: "WY", name: "Wyoming"},
      ],
      matchingState: false
    }
  },
  computed: {
    filteredStates() {
      const matchingState = this.states.find(
          (state) =>
              state.name.toLowerCase() === this.searchTerm.toLowerCase()
      );

      if (matchingState) {
        // this.showList = false;
        this.hideStates()
      }

      return this.states.filter((state) =>
          state.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },

    leasingCompanies() {
      console.log(JSON.parse(localStorage.getItem('leasingCompanies')));
      return JSON.parse(localStorage.getItem('leasingCompanies'))
    }
  },
  methods: {
    validateFields(label, value) {
      if (value === '') {
        this.$store.state.isError = true
        this.$store.state.errorMsg = label + ' is required.'
        return;
      }

      this.$store.state.isError = false

    },
    hideStates() {
      console.log('test')
      this.showList = false
      console.log(this.showList)
    },
    onInput() {
      this.showList = this.searchTerm.length > 0;
      // this.$parent.keyword = value.toLowerCase()
      this.$parent.searchFields.state = this.searchTerm.toLowerCase()
      this.$parent.searchFields.plateNumber = this.plateNum.toLowerCase()
      this.$parent.searchFields.unitNumber = this.unitNum.toLowerCase()
      this.$parent.searchFields.vinNumber = this.vinNum.toLowerCase()
      this.$parent.searchFields.leasingCompany = this.leasingCompany.toLowerCase()

      this.$emit("searchVehicle");
    },
    selectState(state) {
      // Set the search term to the selected state's name
      this.searchTerm = state.name;

      this.selectedStateCode = state.code;
      // Hide the list
      this.showList = false;
      this.$parent.keyword = state.name.toLowerCase()
      this.$emit("searchEvent");
    },
  },
};
</script>

<style>

</style>