<template>
  <div class="mx-auto sm:w-full">
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="100.000000pt" height="100pt" viewBox="0 0 926.000000 510.000000"
           preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,510.000000) scale(0.100000,-0.100000)"
           stroke="none">
          <path d="M0 2550 l0 -2550 4630 0 4630 0 0 2550 0 2550 -4630 0 -4630 0 0
-2550z m4529 2366 c-2 -2 -17 -6 -34 -10 -22 -5 -26 -4 -15 4 14 9 59 15 49 6z
m-99 -40 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z m222 -16 c41 -12 132 -100 161 -157 28 -54 33 -134 12 -190 -22 -58 -73
-143 -86 -143 -5 0 -32 -11 -61 -25 -33 -16 -69 -25 -100 -25 -55 0 -141 35
-187 76 -38 33 -88 112 -97 151 -3 16 -10 38 -16 51 -8 16 -8 28 0 45 6 12 18
40 27 62 31 75 40 88 70 98 16 6 38 21 48 34 10 12 25 23 33 23 8 0 14 5 14
11 0 14 123 6 182 -11z m-639 -121 c-12 -24 -13 -48 -7 -122 5 -51 8 -96 9
-102 0 -5 -11 -13 -25 -17 -199 -59 -285 -91 -394 -145 -149 -75 -159 -81
-286 -188 -140 -117 -174 -150 -260 -255 -96 -116 -135 -174 -178 -265 -19
-38 -37 -76 -41 -84 -5 -10 -39 -14 -144 -15 -141 -1 -149 1 -123 39 7 11 23
52 35 90 12 39 31 87 44 107 12 20 37 63 56 95 18 32 37 60 40 63 16 12 81 98
81 107 0 5 27 36 60 69 33 32 60 63 60 69 0 5 -29 -20 -65 -55 -36 -35 -65
-60 -65 -54 0 6 3 14 8 18 4 4 7 11 8 14 3 25 18 62 24 62 5 0 12 9 15 21 4
12 12 18 21 15 8 -3 14 1 14 9 0 8 5 15 10 15 6 0 10 7 10 15 0 8 6 15 13 15
17 1 61 39 54 47 -4 3 5 12 20 20 31 16 47 2 37 -32 -6 -18 -3 -17 22 5 34 30
91 70 99 70 3 0 16 10 28 23 21 23 51 40 72 42 6 0 26 12 45 25 19 14 51 33
70 44 19 10 44 26 55 36 29 26 198 104 280 130 39 12 78 26 87 31 10 5 26 9
37 9 25 0 120 29 151 46 37 20 40 17 23 -17z m1273 -15 c152 -50 380 -157 489
-229 38 -25 73 -45 77 -45 23 0 226 -168 328 -271 164 -166 270 -309 360 -486
28 -57 56 -105 61 -108 5 -4 9 -15 9 -26 0 -18 -8 -19 -114 -19 -174 0 -168
-3 -221 102 -92 183 -286 402 -497 560 -96 72 -139 98 -308 183 -87 44 -298
125 -325 125 -12 0 -15 17 -15 99 0 54 -3 113 -6 130 -5 23 -3 31 8 31 8 0 78
-21 154 -46z m-1499 -10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-657 -322 c0 -27 -38 -56 -55 -42 -11 9 -9 17 12 40 27 31 43 31 43 2z m30
12 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z
m-90 -74 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m-277 -285 c-3 -8 -9 -15 -13 -15 -5 0 -12 -9 -15 -20 -3 -11 -12 -20 -20 -20
-7 0 -16 -10 -18 -22 -4 -20 -4 -19 -6 5 -1 18 4 27 14 27 8 0 15 6 15 13 0
19 30 58 40 52 5 -3 7 -12 3 -20z m3325 -940 c-3 -3 -13 -2 -22 1 -14 5 -15 9
-4 15 13 8 36 -6 26 -16z m1587 -28 c61 -3 153 -2 205 3 52 4 96 6 98 5 1 -1
-1 -38 -5 -81 l-8 -79 -150 0 -150 0 -3 -92 -3 -92 147 -3 147 -3 4 -57 c4
-55 3 -59 -22 -68 -14 -5 -84 -10 -155 -10 -71 0 -131 -3 -133 -7 -2 -5 0 -43
6 -85 l10 -78 159 0 158 0 0 -59 c0 -98 18 -91 -236 -91 l-224 0 0 415 0 415
23 -14 c14 -8 63 -16 132 -19z m-5171 3 c165 0 204 -11 276 -76 57 -52 82
-105 82 -173 0 -77 -20 -118 -95 -188 -53 -49 -78 -65 -133 -82 -37 -11 -87
-21 -110 -21 -24 0 -46 -5 -50 -11 -3 -6 -6 -62 -5 -125 l2 -114 -69 0 -70 0
-7 93 c-3 51 -5 233 -3 404 3 288 4 310 20 302 9 -5 82 -9 162 -9z m761 0
c130 0 139 -1 199 -30 48 -22 72 -41 98 -77 35 -48 68 -133 68 -173 0 -61
-119 -213 -177 -225 -15 -3 -46 -10 -68 -15 -22 -5 -66 -12 -97 -15 l-57 -6
-3 -127 -3 -127 -68 -5 -67 -5 0 413 c0 405 0 413 19 402 11 -5 81 -10 156
-10z m1505 0 c85 -3 167 -11 182 -17 38 -17 116 -100 144 -155 15 -31 24 -64
24 -95 0 -83 -56 -200 -108 -227 -58 -30 -58 -39 -1 -113 29 -37 74 -93 101
-122 26 -30 48 -59 48 -63 0 -9 -134 -11 -178 -2 -24 5 -34 15 -51 53 -11 25
-36 65 -55 88 -34 39 -120 103 -139 103 -5 0 -8 -42 -7 -92 1 -51 1 -108 1
-125 l-1 -33 -64 0 c-36 0 -68 4 -71 10 -3 5 -8 190 -11 411 -5 387 -5 401 13
393 10 -5 88 -11 173 -14z m751 -5 c51 3 149 7 217 10 l122 5 0 -28 c-1 -32
-16 -101 -25 -110 -3 -3 -72 -9 -153 -12 l-147 -5 -3 -98 -3 -99 141 4 141 3
-3 -70 -3 -70 -115 -2 c-63 -2 -125 -5 -138 -8 l-23 -6 7 -148 7 -149 -63 -7
c-35 -4 -66 -3 -71 2 -9 9 -30 790 -21 806 2 5 12 1 21 -7 14 -12 36 -15 112
-11z m-3717 -19 c100 -45 180 -115 228 -201 31 -57 33 -66 33 -160 0 -92 -2
-104 -29 -153 -82 -147 -270 -282 -394 -282 -35 0 -129 23 -172 43 -31 13
-139 95 -163 122 -27 31 -94 174 -108 232 -11 46 -10 63 5 128 15 65 25 83 69
131 72 79 187 154 257 168 14 2 68 3 121 1 75 -3 109 -9 153 -29z m2516 -48
l0 -76 -72 -7 c-40 -4 -113 -5 -161 -3 -84 3 -88 2 -83 -17 3 -11 6 -50 6 -87
l0 -68 146 0 147 0 -7 -62 c-4 -34 -10 -66 -12 -70 -3 -4 -67 -8 -144 -8 -116
0 -139 -2 -144 -16 -8 -20 -8 -93 0 -130 l5 -29 162 0 162 0 3 -71 3 -71 -38
-7 c-21 -3 -129 -6 -239 -6 l-201 0 -7 38 c-8 47 -21 735 -14 753 4 11 51 14
247 14 l241 0 0 -77z m1908 -250 l2 -328 113 6 c61 2 127 7 146 9 l33 4 -6
-62 c-4 -34 -10 -72 -13 -85 l-5 -23 -197 6 -196 6 -2 47 c-2 26 -4 206 -5
400 l-3 353 65 -3 65 -3 3 -327z m1014 249 l-4 -65 -146 -7 c-81 -3 -153 -8
-159 -10 -9 -3 -13 -31 -13 -95 l0 -90 145 0 145 0 0 -54 c0 -42 -4 -56 -17
-61 -10 -4 -79 -11 -153 -14 l-135 -6 -3 -45 c-1 -25 1 -65 5 -89 l8 -44 169
2 c153 2 168 1 162 -14 -3 -9 -6 -43 -6 -76 l0 -59 -229 0 -228 0 -7 108 c-3
59 -7 238 -7 397 l-1 290 234 5 c128 3 235 2 238 -1 3 -4 4 -36 2 -72z m1506
1 l-3 -83 -97 -3 -98 -3 0 -47 c-2 -171 -12 -569 -15 -579 -3 -9 -26 -13 -70
-13 l-65 0 0 88 c1 48 5 159 10 247 5 88 6 193 3 233 l-6 72 -103 0 -104 0 0
70 c0 39 0 73 0 78 0 4 120 9 267 12 148 3 272 7 276 8 4 1 7 -35 5 -80z
m-7902 63 c16 -5 41 -12 57 -15 44 -10 107 -73 107 -107 0 -16 -4 -29 -9 -29
-5 0 -19 -15 -32 -34 l-23 -34 -51 29 c-64 36 -129 49 -196 38 -89 -14 -129
-41 -184 -124 -85 -129 -45 -281 90 -344 34 -16 65 -21 126 -21 91 0 135 15
173 61 30 35 46 37 46 6 0 -13 13 -42 30 -65 l29 -41 -40 -34 c-22 -19 -68
-49 -103 -66 -83 -41 -172 -43 -264 -7 -87 34 -124 59 -178 119 -184 204 -127
522 114 636 26 12 54 26 62 31 20 12 209 12 246 1z m5592 -1211 c147 0 152 -3
111 -62 -12 -16 -46 -77 -76 -136 -132 -256 -324 -477 -594 -682 -137 -105
-442 -250 -649 -310 -41 -12 -88 -26 -103 -32 -36 -14 -42 -6 -28 33 20 57 6
173 -22 191 -12 7 32 28 60 28 17 0 108 28 135 41 10 5 23 9 29 9 32 0 348
170 357 192 2 4 8 8 14 8 6 0 27 14 48 30 21 17 41 30 45 30 5 0 31 21 59 46
28 26 65 60 82 75 91 82 167 179 267 344 7 11 14 22 17 25 29 29 63 98 68 136
4 41 7 45 26 40 12 -3 81 -6 154 -6z m-3601 -30 c6 -27 72 -163 106 -220 89
-148 267 -335 407 -427 30 -20 57 -40 60 -43 7 -9 199 -122 240 -141 88 -42
282 -109 314 -109 17 0 18 -7 10 -97 -4 -54 -8 -110 -7 -125 0 -32 0 -32 -69
-13 -118 32 -324 127 -498 230 -47 27 -103 59 -125 70 -96 47 -312 236 -418
365 -34 41 -76 91 -94 110 -53 59 -72 86 -120 172 -60 108 -123 234 -123 245
0 4 70 8 155 8 l156 0 6 -25z m1798 -806 c47 -22 122 -105 150 -167 56 -119 7
-264 -108 -324 -67 -35 -164 -38 -214 -8 -18 11 -33 22 -33 25 0 4 38 4 85 2
87 -5 161 12 133 29 -7 5 -17 4 -22 -1 -15 -15 -26 -12 -20 5 7 16 -4 18 -86
17 -25 -1 -58 -1 -74 -1 -17 0 -44 11 -62 24 -18 14 -39 25 -47 25 -55 0 -87
149 -51 236 25 60 100 121 184 148 35 11 134 5 165 -10z"/>
          <path d="M3005 4260 c-10 -11 -13 -20 -8 -20 13 0 38 29 31 35 -3 3 -13 -4
-23 -15z"/>
          <path d="M3100 4380 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
          <path d="M2508 2924 c-5 -4 -8 -58 -8 -121 l0 -113 79 0 c70 0 83 3 109 25 54
45 55 157 2 188 -30 18 -170 34 -182 21z"/>
          <path d="M3264 2914 c-11 -42 3 -206 18 -215 7 -5 38 -9 69 -9 49 0 61 4 87
28 47 44 55 149 14 185 -26 22 -80 37 -133 37 -44 0 -49 -2 -55 -26z"/>
          <path d="M4766 2924 c-3 -9 -6 -65 -6 -124 l0 -109 41 -11 c56 -15 90 -6 134
40 33 34 38 45 37 85 0 60 -19 104 -46 110 -31 7 -138 25 -147 25 -4 0 -10 -7
-13 -16z"/>
          <path d="M1560 2921 c-33 -12 -58 -33 -97 -81 -29 -36 -59 -79 -68 -97 -45
-93 17 -220 139 -283 127 -66 295 -21 368 98 12 20 28 43 35 50 34 35 1 159
-60 225 -50 53 -84 72 -169 92 -77 18 -91 18 -148 -4z"/>
        </g>
      </svg>
    </div>
</template>

<script>
export default {
  name: 'LogoPanel',
  methods: {
    //
  },
}
</script>