<template>
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
        <div class="mt-3 sm:mt-5" style="width: 370px;">
          <ErrorAlert v-if="$store.state.isError"/>

          <div class="mt-2 sm:col-span-2 sm:mt-0" v-if="$parent.resolutionDate !== ''">
            <h3 class="block text-base font-semibold leading-6 text-gray-900">Resolution Date: {{ $parent.resolutionDate }}</h3>
          </div>

          <div class="space-y-8 pb-6 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 ">
              <label for="resolution_description" class="block text-base font-semibold leading-6 text-gray-900">Description</label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <textarea
                    rows="4"
                    name="resolution_description"
                    id="resolution_description"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Defect description"
                    v-model="$parent.resolutionDescription"
                    required
                />
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6"  v-if="$parent.resolutionDate !== ''">
              <label for="resolution_description" class="block text-base font-semibold leading-6 text-gray-900">Image</label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <img class="mb-5 mt-5 max-h-80 max-w-100 h-70" :src="$parent.resolutionFile" />
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6"  v-if="$parent.resolutionDate === ''">
              <label for="resolution_description" class="block text-base font-semibold leading-6 text-gray-900">Image</label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <div class="flex w-16 flex-shrink-0 items-center justify-center text-sm font-medium">
                  <label for="upload-button">
                    <input id="resolution_document_file" name="resolution_document_file" accept="image/*" capture="environment"  type="file" hidden @change="renderImage('document_file', 'resolution_document_file')">
                    <button
                        type="button"
                        class="bg-white-600 p-0 text-gray-800 hover:bg-white-500 border-0"
                        @click="chooseFile('resolution_document_file')"
                    >
                          <span class="fa-stack fa-2x mt-3 mb-2 item-center">
                            <img id="document_file" class="max-h-85 max-w-70 object-center invisible h-0" src="#" v-if="isImageSelected"  width="70" height="85"/>
                            <i class="fa-solid fa-camera" v-if="!isImageSelected"></i>
                          </span>
                    </button>
                  </label>
                </div>
              </div>
            </div>


          </div>
        </div>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <div class="columns-2"  v-if="$parent.resolutionDate === ''">
              <div>
                <button
                    type="button"
                    class="block mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    @click="hidePreview"
                >
                 Close
                </button>
              </div>
              <div>
                <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-green-300 hover:bg-green-700 sm:col-start-1 sm:mt-0"
                    @click="saveResolution"
                >
                  Save
                </button>
              </div>
            </div>

            <div v-if="$parent.resolutionDate !== ''">
              <button
                  type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-red-700 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-red-300 hover:bg-red-700 sm:col-start-1 sm:mt-0"
                  @click="hidePreview"
              >
                Close
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import heic2any from "heic2any";
import ErrorAlert from "@/components/Common/ErrorAlert.vue";

export default {
  name: 'ResolutionForm',
  components: {ErrorAlert},
  props: [
    'isCreate'
  ],
  data() {
    return {
      isImageSelected: false,
      resolution_document_file: '',
    }
  },
  methods: {
    hidePreview() {
      this.$parent.showResolution = false
    },
    chooseFile(fileInput) {
      document.getElementById(fileInput).click()
    },
    saveResolution() {
      let t = this
      if (this.$parent.resolutionFile !== '') {
        this.getImagesFromLocal('resolution_document_file')
      }

      if (this.$parent.resolutionDescription === '') {
        this.$store.state.isError = true
        this.$store.state.errorMsg = 'Description is required.'
        return
      }

      this.$store.state.login.userData = JSON.parse(localStorage.getItem('userData'))
      this.$store.state.isError = false
      this.$store.state.errorMsg = ''

      let data = {
        id: this.$parent.defectId,
        user_id: this.$store.state.login.userData.id,
        vehicle_id: this.$store.state.vehicle.selectedVehicle.id,
        resolution_description:  this.$parent.resolutionDescription,
        resolution_document_file: this.$parent.resolutionFile,
        tenantId: localStorage.getItem('tenantId')
      }

      this.$store.dispatch('dvir/updateDefect', data)
          .then(result => {
            console.log(result); // Use the response
            setTimeout(function () {
              t.$emit('setDvir', 1);
              // Hide modal
              t.$parent.showResolution = false
              t.$store.dispatch('vehicle/get')
              t.$store.dispatch('vehicle/selected')
              t.$store.dispatch('dvir/get', 1)
            }, 500)
          })
          .catch(error => {
            console.log('ERROR in someMethod: ' + error); // Handle the error
          });
    },
    renderImage(id, fileInput) {
      const [file] = document.getElementById(fileInput).files
      console.log(file)

      const t = this
      if (file) {
        if (fileInput === 'resolution_document_file') {
          this.isImageSelected = true
        }

        setTimeout(async function () {
          let convertedBlob = file;
          console.log(file.type)
          if (file.type === 'image/heif' || file.type === 'image/heic') {
            convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
          }

          let selectedImage = document.getElementById(id)
          selectedImage.src = URL.createObjectURL(convertedBlob)

          selectedImage.classList.remove('invisible')
          selectedImage.classList.remove('h-0')
          selectedImage.classList.add('resolutionImage')

          const reader = new FileReader()
          reader.readAsDataURL(convertedBlob);
          reader.onload = function(event) {
            const base64String = event.target.result.split(',')[1]; // Extract the Base64 part
            //localStorage.setItem('dvirImage_'+ fileInput, base64String);
            t.saveImageToLocal(fileInput, base64String)
            t.$parent.resolutionFile = base64String
          };
          // reader.readAsDataURL(file)
        }, 1000)
      }
    },
    saveImageToLocal(type, stringFile) {
      this.indexedDB = window.indexedDB
      if (!this.indexedDB) {
        console.log("IndexedDB could not be found in this browser.");
      }
      const request = indexedDB.open("ResolutionImage", 1);

      request.onerror = function (event) {
        console.error("An error occurred with IndexedDB");
        console.error(event);
      };

      request.onupgradeneeded = function () {
        const db = request.result;
        db.createObjectStore("images", { keyPath: "type" });
      };

      request.onsuccess = function () {
        console.log("Database opened successfully");
        const db = request.result;
        if (db.objectStoreNames.length === 0) {
          console.log('The database has no object stores.');
          db.close()
        } else {
          const transaction = db.transaction("images", "readwrite");
          const store = transaction.objectStore("images");
          store.put({ type: type, file: stringFile });
          transaction.oncomplete = function () {
            db.close()
          }
        }
      }
    },
    getImagesFromLocal(id)
    {
      const t = this
      this.indexedDB = window.indexedDB
      if (!this.indexedDB) {
        console.log("IndexedDB could not be found in this browser.")
      }

      const request = indexedDB.open("ResolutionImage", 1)

      request.onerror = function (event) {
        console.error("An error occurred with IndexedDB")
        console.error(event)
      };

      request.onsuccess = function () {
        const db = request.result
        if (db.objectStoreNames.length === 0) {
          console.log('The database has no object stores.');
          db.close()
        } else {
          console.log('DB: ' + JSON.stringify(db));
          const transaction = db.transaction(["images"], "readwrite")
          const store = transaction.objectStore("images")
          const idQuery = store.get(id)

          transaction.onerror = function(event) {
            console.log('Transaction error:', event.target.error);
          };

          idQuery.onsuccess = function () {
            if (id === 'resolution_document_file') {
              if (typeof idQuery.result !== 'undefined') {
                t.$parent.resolutionFile = idQuery.result.file
                return;
              }
            }
          }
        }
      }
    },
  },
  mounted() {
    //
  }
}
</script>