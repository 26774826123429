<template>
  <div class="mx-auto sm:w-full">
    <img :src="'https://copperfleet.com/storage/' + tenantLogo " width="150" class="m-10"/>
  </div>
</template>

<script>
export default {
  name: 'TenantLogoPanel',
  data() {
    return {
      tenantLogo: '',
    }
  },
  methods: {
    //
  },
  mounted() {
    this.tenantLogo = localStorage.getItem('tenantLogo')
  }
}
</script>