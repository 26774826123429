<template>
  <div class="sm:mx-auto sm:w-full sm:max-w-sm m-5">
    <div class="mt-5 p-5 bg-white">
      <form>
        <div class="">

            <div class="space-y-8 pb-6 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0 mt-0">
              <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 ">
                <label for="username" class="block text-base font-semibold leading-6 text-gray-900">Domain Name</label>
                <div class="mt-2 sm:col-span-2 sm:mt-0">
                  <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                        type="text"
                        name="domain"
                        id="domain"
                        v-model="$store.state.tenant.domain"
                        class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Enter company domain name"
                    >
                  </div>
                </div>
              </div>
            </div>

          <div class="text-center py-10">
            <button
                id="certifyBtn"
                type="button"
                @click="submitTenant"
                class="rounded-full bg-white px-4 py-2.5 text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 justify-items-center w-3/4"
                :disabled="$store.state.isProcessing"
            >
              {{ ($store.state.isProcessing ? 'Processing...' : 'Save') }}
            </button>
          </div>
        </div>
      </form>
    </div>


  </div>
</template>

<script>

export default {
  name: 'TenantView',
  data() {
    return {
      name: '',
      domain: '',
    }
  },
  components: {

  },
  methods: {
    submitTenant() {
      /*if (this.$store.state.companyName === '') {
        this.$store.state.isError = true
        this.$store.state.isSuccess = false
        this.$store.state.errorMsg = 'Company name is required.'
        return;
      }*/

      if (this.$store.state.domain === '') {
        this.$store.state.isError = true
        this.$store.state.isSuccess = false
        this.$store.state.errorMsg = 'Domain name is required.'
        return;
      }

      this.$store.dispatch('tenant/tenant')
    },
  },
  props: {
    msg: String
  },
  mounted() {
    console.log('IS_DOMAIN: ' + localStorage.getItem('domain'))
    this.$store.state.tenant.domain = localStorage.getItem('domain')
  }
}
</script>