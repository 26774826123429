<template>
  <TenantView />
</template>

<script>
import TenantView from "@/components/Settings/Tenant.vue";

export default {
  name: 'SettingView',
  components: {
    TenantView

  },
  mounted() {
    /*console.log(localStorage.getItem('isLogin'))
    if (localStorage.getItem('isLogin') === 'true') {
      window.location.href = '/tenant'
    }*/
  }
}
</script>
