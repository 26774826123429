<template>
  <Login />
</template>

<script>
import Login from '@/components/Login/Login.vue'

export default {
  name: 'HomeView',
  components: {
    Login
  },
  data() {
    return {
      appVersion: 'Loading...',
      isCompanySet: false,
    }
  },
  methods: {
    clearPWACache() {
      // Check if the browser supports the Cache API
      if ('caches' in window) {
        caches.keys().then(cacheNames => {
          cacheNames.forEach(cacheName => {
            caches.delete(cacheName).then(() => {
              console.log(`Cache ${cacheName} deleted successfully.`);
            }).catch(err => {
              console.error(`Error deleting cache ${cacheName}:`, err);
            });
          });
        });
      }
    }
  },
  mounted() {
    var tenantId = localStorage.getItem('tenantId')
    if (tenantId != null) {
      // window.location.href = '/settings'
      this.isCompanySet = true
    }

    this.clearPWACache();

    if (indexedDB.databases) {
      indexedDB.databases().then(databases => {
        databases.forEach(db => {
          const dbName = db.name;
          if (dbName) {
            let deleteRequest = indexedDB.deleteDatabase(dbName);

            deleteRequest.onsuccess = function() {
              console.log(`Database ${dbName} deleted successfully.`);
            };

            deleteRequest.onerror = function(event) {
              console.error(`Error deleting database ${dbName}:`, event);
            };

            deleteRequest.onblocked = function(event) {
              console.warn(`Delete request blocked for database ${dbName}.`, event);
            };
          }
        });
      }).catch(error => {
        console.error('Error listing databases:', error);
      });
    } else {
      console.warn('indexedDB.databases() is not supported in this browser.');
    }
    if (localStorage.getItem('isLogin') === 'true') {
      window.location.href = '/dashboard'
    }

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        if (registration.active) {
          const messageChannel = new MessageChannel();
          messageChannel.port1.onmessage = (event) => {
            if (event.data && event.data.version) {
              this.appVersion = event.data.version; // Set the version
              console.log("Service Worker Version:", event.data.version);
            }
          };

          registration.active.postMessage(
              { type: "GET_VERSION" },
              [messageChannel.port2]
          );
        }
      });
    }
  }
}
</script>
