import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/css/app.css'
import './assets/fontawesome/css/all.css'

createApp(App)
    .use(store)
    .use(router)
    .mount('#app')


if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/service-worker.js").then((registration) => {
        registration.onupdatefound = () => {
            const newWorker = registration.installing;

            newWorker.onstatechange = () => {
                if (newWorker.state === "installed" && navigator.serviceWorker.controller) {
                    alert("A new version is available. Refresh the page to update.");
                    window.location.reload();
                }
            };
        };
    });
}
