import axios from 'axios';
import { API_URL } from '@/config/constants';
export default {
    namespaced: true,
    state: {
        selectedDvir: {},
        dvir: [],
        hasSelectedDvir: false,
        defectCategories: {},
        resolutionFile: '',
    },
    getters: {
        data: state => state.dvir,
    },
    actions: {
        get({state, commit, rootState}, page) {
            let data = {
                vehicleId: rootState.vehicle.selectedVehicle.id,
                page: page,
                tenantId: localStorage.getItem('tenantId')
            }
            console.log('DATA: ' + JSON.stringify(data))
            const config = {
                headers: {
                    'Authorization': `Bearer `+ localStorage.getItem('apiToken'),
                    'Content-Type': 'multipart/form-data'
                }
            };

            rootState.isProcessing = true;
            axios.post(API_URL + 'dvir/get', data, config)
                .then(function (response) {
                    localStorage.setItem('dvirList', JSON.stringify(response.data.dvir))
                    commit('setDvirList', response.data)
                    // console.log('RESPONSE: ' + JSON.stringify(response.data.dvir.data.length))
                    rootState.isProcessing = false;
            }).catch((error) => {
                    // console.log('ERROR: ' + error)
                    state.errorMsg = error.response.data.message
                    state.isError = true
                    rootState.isProcessing = false;
            });
        },
        getDvirList({state}) {
            return state.dvir
        },
        selected({ commit }) {
            commit('setSelectedDvir')
        },
        details({state}) {
            console.log(state.selectedDvir)
        },
        async getDvirDefectCategories({rootState}) {
            let data = {
                tenantId: localStorage.getItem('tenantId')
            }
            const config = {
                headers: {
                    'Authorization': `Bearer `+ localStorage.getItem('apiToken'),
                    'Content-Type': 'multipart/form-data'
                }
            };

            await axios.post(API_URL + 'dvir/defect-category', data, config)
                .then(function (response) {
                    localStorage.setItem('defectCategories', JSON.stringify(response.data.categories))
                }).catch((error) => {
                    console.log('ERROR: ' + error)
                    rootState.errorMsg = error.response.data.message
                    rootState.isError = true
                });
        },
        store({commit, rootState}, data) {
            console.log(JSON.stringify(data))
            const config = {
                headers: {
                    'Authorization': `Bearer `+ localStorage.getItem('apiToken'),
                    'Content-Type': 'multipart/form-data'
                }
            };

            rootState.isProcessing = true
            axios.post(API_URL + 'dvir/store', data, config)
                .then(function (response) {
                    localStorage.setItem('dvirList', JSON.stringify(response.data.dvir))
                    commit('setDvirList', response.data)
                    rootState.successMsg = response.data.message
                    rootState.isSuccess = true
                    rootState.isError = false
                    rootState.errorMsg = ''
                    rootState.isProcessing = false
                    rootState.defects = [];

                    localStorage.setItem('successMsg', response.data.message)
                    localStorage.setItem('isSuccess', true)
                    localStorage.removeItem('started_at')
                    window.location.href = '/dashboard?i='+rootState.isSuccess
                }).catch((error) => {
                    console.log('ERROR: ' + error)
                    rootState.errorMsg = error.response.data.message
                    rootState.isError = true
                    rootState.isProcessing = false
                });
        },
        updateDefect({commit, rootState}, data) {
            console.log('RESOLUTION: ' + JSON.stringify(data))
            rootState.isSuccess = false
            const config = {
                headers: {
                    'Authorization': `Bearer `+ localStorage.getItem('apiToken'),
                    'Content-Type': 'multipart/form-data'
                }
            };

            rootState.isProcessing = true
            return axios.post(API_URL + 'dvir/defect/update', data, config)
                .then(function (response) {
                    localStorage.setItem('dvirList', JSON.stringify(response.data.dvir))
                    commit('setDvirList', response.data)
                    rootState.successMsg = response.data.message
                    rootState.isSuccess = true
                    rootState.isError = false
                    rootState.errorMsg = ''
                    rootState.isProcessing = false

                    localStorage.setItem('successMsg', response.data.message)
                    localStorage.setItem('isSuccess', true)
                    // window.location.href = '/dashboard?i='+rootState.isSuccess
                    return response.data;
                }).catch((error) => {
                console.log('ERROR: ' + error)
                rootState.errorMsg = error.response.data.message
                rootState.isError = true
                rootState.isProcessing = false
            });
        }
    },
    mutations: {
        getDvir(state, vehicleId) {
            console.log('TEST: '+ vehicleId)
        },
        setDvirList(state, data) {
            state.dvir = data.dvir
        },
        setSelectedDvir(state) {
            state.selectedDvir = {}
            state.hasSelectedDvir = true
        },
        setDefectCategories(state, data) {
            console.log(data)
            // data = data
            state.defectCategories = data.categories

        }
    }
}