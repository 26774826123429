<template>
  <router-view/>

  <SpinnerIcon v-if="$store.state.isProcessing" />
</template>

<script>

import SpinnerIcon from "@/components/Common/Spinner.vue";

export default {
  name: 'PastDvirPanel',
  components: {SpinnerIcon},
  methods: {
    //
  },
  data() {
    return {
      appVersion: "Loading...", // Placeholder for version
    };
  },
  mounted() {
    document.addEventListener('gesturestart', function(event) {
      event.preventDefault();
    });

    document.addEventListener('gesturechange', function(event) {
      event.preventDefault();
    });

    document.addEventListener('gestureend', function(event) {
      event.preventDefault();
    });


  }
}
</script>

<style>
#spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
}
html, body {
  -webkit-user-select: none; /* Chrome/Safari */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard */
  touch-action: manipulation; /* Prevent default gestures */
}
</style>