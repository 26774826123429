import { createStore } from 'vuex'
import login from './modules/login'
import vehicle from "@/store/modules/vehicle"
import dvir from "@/store/modules/dvir"
import tenant from "@/store/modules/tenant"

export default createStore({
  state: {
    apiToken: '',
    pageTitle: 'Vehicle Information',
    errorMsg: '',
    successMsg: '',
    isSuccess: '',
    isError: false,
    emptyDefects: [],
    parentPage: '/dashboard',
    parentPageTitle: 'Dashboard',
    isProcessing: false,
    isRefresh: true,
    isOtp: false
  },
  getters: {
  },
  mutations: {
    setPageTitle(state, title) {
      state.pageTitle = title
    },
    setParentPage(state, parentPage) {
      state.parentPage = parentPage
    }
  },
  actions: {
    setPageTittle({commit}, title, parentPage) {
      console.log('TITLE: ' +parentPage);
      commit('setPageTitle', title)
    },
    setParentPage({commit}, parentPage) {
      console.log('PARENT: ' +parentPage);
      commit('setParentPage', parentPage)
    }
  },
  modules: {
    login,
    vehicle,
    dvir,
    tenant,
  }
})
