<template>
  <div class="text-center py-5">
    <button
        v-if="$parent.isShowRentalVehicle"
        type="button"
        @click="showRentalVehiclePage"
        class="rounded-full bg-white px-4 py-2.5 text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 justify-items-center w-3/4"
    >
      Rental Vehicles
    </button>
  </div>

  <div class="m-5" v-if="isShowVehicleList">
    <label for="search" class="block text-sm font-medium leading-6 text-gray-900">Search Rental Vehicle</label>
<!--    <label for="search" class="block text-sm font-medium leading-6 text-gray-900">Search Rental Vehicle</label>
    <div class="relative mt-2 flex items-center">
      <input
          type="text"
          name="search"
          id="search"
          class="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          v-model="keyword"
          v-on:keyup="search"
          placeholder="unit number, vehicle make/model/year/color, state/license plate, and vin number"
      >
      <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
        <a class="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400" href="">
          <i class="fa-solid fa-magnifying-glass"></i>
        </a>
      </div>
    </div>-->

    <ErrorAlert v-if="$store.state.isError"/>
    <RentalSearchForm @searchVehicle="filteredVehicles"/>

    <div class="mb-10 mt-2" v-if="isSearch && resultCount > 0">
      <ul role="list" class="divide-y divide-gray-100 bg-white p-5 mb-1">
        <li class="flex items-center justify-between gap-x-6 py-5 bg-white-500" v-for="(vehicle) in vehicleListResult" :key="vehicle.id">
          <a href="#" @click="selectVehicle(vehicle)">
            <div class="flex min-w-0 gap-x-4">
              <div class="mr-4 flex-shrink-0 self-center text-2xl text-green-800">
                <img class="w-24 text-gray-800 vehicle-image" :src="(vehicle.image == null || vehicle.image === '' ? 'img/no-image.png' : vehicle.image)" />
              </div>
              <div class="min-w-0 flex-auto">
                <p class="text-sm font-semibold leading-6 text-gray-900">
                  {{ vehicle.unit_number}} - {{ vehicle.year }}
                  {{ vehicle.make }}
                  {{ vehicle.model }}
                </p>
                <p class="mt-1 truncate text-xs leading-5 text-gray-500">
                  {{ vehicle.state_registered}} {{ vehicle.license_plate_number}}
                </p>
                <p class="mt-1 truncate text-xs leading-5 text-gray-500">
                  {{ vehicle.vin_number}}
                </p>
                <p class="mt-1 truncate text-xs leading-5 text-gray-500">
                  {{ vehicle.leasing_agency}}
                </p>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>

    <!-- If no record, create a rental vehicle -->
    <div class="m-10 py-10 text-center" v-if="isSearch && resultCount <= 0">
      <button
          id="certifyBtn"
          type="button"
          @click="submitRental()"
          class="rounded-full bg-white px-4 py-2.5 text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 justify-items-center w-3/4"
          :disabled="$store.state.isProcessing"
      >
        {{ ($store.state.isProcessing ? 'Processing...' : 'Create') }}
      </button>
    </div>

  </div>

</template>

<script>

import RentalSearchForm from "@/components/Common/RentalSearchForm.vue";
import ErrorAlert from "@/components/Common/ErrorAlert.vue";
export default {
  name: 'RentalVehiclePanel',
  components: {
    RentalSearchForm,
    ErrorAlert,
  },
  data() {
    return {
      isSearch: false,
      keyword: '',
      vehicleList: [],
      vehicleListResult: [],
      resultCount: 0,
      isShowVehicleList: false,
      searchFields: {
        state: "",
        plateNumber: "",
        unitNumber: "",
        vinNumber: "",
        leasingCompany: "",
      },
    }
  },
  methods: {
    submitRental() {
      this.$store.state.isProcessing = true;
      this.$store.dispatch('vehicle/store', this.searchFields)
    },
    showRentalVehiclePage() {
      console.log('clicked rental vehicle')
      this.$parent.isShowRentalVehicle = true
      this.$store.dispatch('setPageTittle', 'Rental Vehicle', '/vehicle-select')
      this.$store.dispatch('setParentPage', '/vehicle-select')
      this.isShowVehicleList = true
      this.$parent.isShowSearchVehicle = false
      this.$parent.isShowSelectedVehicle = false
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    },
    selectVehicle(vehicle) {
      localStorage.setItem('selectedVehicle', JSON.stringify(vehicle))
      this.$store.dispatch('vehicle/selected')
      this.$router.push('/dashboard')
    },
    wildcardToRegex(pattern) {
      return new RegExp(pattern.split('*').join('.*') + '$');
    },
    searchInJson(json, pattern) {
      const regex = this.wildcardToRegex(pattern);
      this.vehicleListResult = []
      const t = this
      console.log(regex)
      function recursiveSearch(obj) {
        for (const key in obj) {
          console.log(typeof obj[key])
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            recursiveSearch(obj[key]);
          } else {
            if (obj[key] !== null) {
              console.log('OBJ1: ' + obj[key])
              console.log('REGEX: ' + regex)
              console.log('TEST: '+ regex.test("'" + obj[key] + "'"))
              if (regex.test(obj[key].toString().toLowerCase())) {
                console.log('OBJ: ' + JSON.stringify(obj[key]))
                t.vehicleListResult.push(obj);
              }
            }
          }
        }
      }

      json.forEach(obj => {
        recursiveSearch(obj);
      });
      t.vehicleListResult = t.getUniqueListBy(t.vehicleListResult, 'id')
    },
    search() {
      console.log(JSON.stringify(this.vehicleList))
      if (this.keyword !== '') {
        const pattern = this.keyword + "*";
        this.searchInJson(this.vehicleList, pattern);
      }
    },
    filteredVehicles() {
      /**
       * "id": 111,
       *     "branch_id": 71,
       *     "vehicle_type_id": 1,
       *     "unit_number": "319060",
       *     "vin_number": "3ALACWFC5KDKB9143",
       *     "license_plate_number": "ABHH84",
       *     "state_registered": "INDIANA",
       *     "registration_number": "2747592",
       *     "vehicle_group_id": 5,
       *     "make": "Freightliner",
       *     "model": "FREIGHTLINER M2",
       *     "year": "2019",
       *     "gross_vehicle_weight_rating": "26000",
       *     "odometer": "95295",
       *     "rental": true,
       *     "leasing_agency": "INTERIUM Penske",
       *     "created_at": "2024-04-05T08:54:15.000000Z",
       *     "updated_at": "2024-08-23T16:50:24.000000Z",
       *     "deleted_at": null,
       *     "status": "Safe to Drive",
       *     "image": null
       */
      let v = this.vehicleList.filter((vehicle) => {
        return (
            // Check for matches only if the search field is not empty
            (typeof vehicle.state_registered === "string" &&
                    vehicle.state_registered
                        .toLowerCase()
                        .includes(this.searchFields.state)) &&
            (typeof vehicle.license_plate_number === "string" &&
                    vehicle.license_plate_number
                        .toLowerCase()
                        .includes(this.searchFields.plateNumber)) &&
            (typeof vehicle.unit_number === "string" &&
                    vehicle.unit_number
                        .toLowerCase()
                        .includes(this.searchFields.unitNumber)) &&
            (typeof vehicle.vin_number === "string" &&
                    vehicle.vin_number
                        .toLowerCase()
                        .includes(this.searchFields.vinNumber)) &&
            (typeof vehicle.leasing_agency === "string" &&
                vehicle.leasing_agency
                    .toLowerCase()
                    .includes(this.searchFields.leasingCompany))
        );
      });
      this.resultCount = v.length;
      this.vehicleListResult = this.getUniqueListBy(v, 'id')
    },
  },
  created() {
    this.vehicleList = JSON.parse(localStorage.getItem('vehicleList'))
    this.vehicleListResult = this.vehicleList
    const t = this
    t.vehicleListResult = []
    t.vehicleList.forEach(function(vehicle) {
      //  unit number, vehicle make/model/year/color, the state/license plate, and vin number.
      if (vehicle.rental) {
        t.vehicleListResult.push(vehicle)
      }
    });
    t.vehicleList = t.getUniqueListBy(t.vehicleListResult, 'id')
    t.resultCount = t.vehicleList.length
    this.$parent.isShowRentalVehicle = true
    t.isSearch = true
  },
  mounted() {
    //
  }
}
</script>