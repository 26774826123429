import axios from 'axios';
import { API_URL } from '@/config/constants';

export default {
    namespaced: true,
    state: {
        tenantData: {},
        domain: '',
        companyName: '',
    },
    getters: {
        data: state => state.tenantData,
    },
    actions: {
        tenant({commit, state, rootState}) {
            let data = {
                site: state.domain.toLowerCase(),
                // companyName: state.companyName
            }
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };

            rootState.isProcessing = true

            setTimeout(function () {
                axios.post(API_URL + 'get-tenant', data, config)
                    .then(function (response) {
                        if (response.data.status) {
                            localStorage.setItem('domain', data.site)
                            localStorage.setItem('tenantId', response.data.tenant.id)
                            localStorage.setItem('tenantLogo', response.data.tenant.logo)
                            localStorage.setItem('tenantData', JSON.stringify(response.data.tenant))
                            commit('setTenantData', response.data.tenant)
                            window.location.href = '/'
                        } else {
                            rootState.errorMsg = response.data.message
                            rootState.isError = true
                            rootState.isSuccess = false
                            rootState.isOtp = false
                            rootState.successMsg = ''
                        }

                        console.log(response.data.user);
                        rootState.isProcessing = false

                    }).catch((error) => {
                        console.log(error)
                        rootState.isError = true
                        rootState.isSuccess = false
                        if (typeof error.response !== 'undefined') {
                            console.log(error.response.data.message)
                            rootState.errorMsg = error.response.data.message
                        }
                        rootState.errorMsg = error.message
                        rootState.isProcessing = false
                        rootState.isOtp = false
                        rootState.successMsg = ''
                });
            }, 1000)

        }
    },
    mutations: {
        setTenantData(state, data) {
            state.tenantData = data
        }
    }
}